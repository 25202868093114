import fetch from "node-fetch";

export default class {
  async getNationalTeams() {
    const res = await fetch(`${process.env.CMS_URL}/nationalTeams/`);
    if (res.ok) {
      return res.json();
    }
    throw new Error(res.statusText);
  }

  async getMatches({
    nationalTeamId,
    type,
    season,
    showAllCompetitions,
    pageSize,
    page,
  }) {
    return fetch(
      `${process.env.API_URL}/nationalTeams/matches?${
        type ? `type=${type}&` : ""
      }${nationalTeamId ? `nationalTeamId=${nationalTeamId}&` : ""}${
        season ? `season=${season}&` : ""
      }${pageSize ? `pageSize=${pageSize}&` : ""}${
        page ? `page=${page}&` : ""
      }${showAllCompetitions ? `onlyActiveCompetitions=true` : ""}`.replace(
        /&$/,
        ""
      )
    );
  }

  async getPastMatches({
    nationalTeamId,
    competitionId,
    type,
    pageSize,
    page,
  }) {
    return fetch(
      `${process.env.API_URL}/nationalTeams/pastMatches?${
        type ? `type=${type}&` : ""
      }${competitionId ? `competitionId=${competitionId}&` : ""}${
        nationalTeamId ? `nationalTeamId=${nationalTeamId}&` : ""
      }${pageSize ? `pageSize=${pageSize}&` : ""}${
        page ? `page=${page}&` : ""
      }`.replace(/&$/, "")
    );
  }

  async getNationalTeamTopScorers({ nationalTeamId, page, pageSize }) {
    const res = await fetch(
      `${process.env.API_URL}/nationalTeams/AllTimeScorers?${
        nationalTeamId ? `nationalTeamId=${nationalTeamId}&` : ""
      }${pageSize ? `pageSize=${pageSize}&` : ""}${
        page ? `page=${page}&` : ""
      }`.replace(/&$/, "")
    );

    if (res.ok) {
      return res.json();
    }
    throw new Error(res.statusText);
  }

  async getNationalTeamAppearances({ nationalTeamId, page, pageSize }) {
    const res = await fetch(
      `${process.env.API_URL}/nationalTeams/AllTimeAppearances?${
        nationalTeamId ? `nationalTeamId=${nationalTeamId}&` : ""
      }${pageSize ? `pageSize=${pageSize}&` : ""}${
        page ? `page=${page}&` : ""
      }`.replace(/&$/, "")
    );

    if (res.ok) {
      return res.json();
    }
    throw new Error(res.statusText);
  }

  async getNationalTeamCoaches({ nationalTeamId, page, pageSize }) {
    const res = await fetch(
      `${process.env.CMS_URL}/nationalTeam/coaches?${
        nationalTeamId ? `nationalTeamId=${nationalTeamId}&` : ""
      }${pageSize ? `pageSize=${pageSize}&` : ""}${
        page ? `page=${page}&` : ""
      }`.replace(/&$/, "")
    );

    if (res.ok) {
      return res.json();
    }
    throw new Error(res.statusText);
  }

  async getNationalTeamCompetitions({ nationalTeamId }) {
    const res = await fetch(
      `${process.env.API_URL}/nationalTeams/competitions?${
        nationalTeamId ? `nationalTeamId=${nationalTeamId}&` : ""
      }`.replace(/&$/, "")
    );

    if (res.ok) {
      return res.json();
    }
    throw new Error(res.statusText);
  }

  async getCompetitionTable({ competitionId }) {
    const res = await fetch(
      `${process.env.API_URL}/nationalTeams/competition/standings?${
        competitionId ? `competitionId=${competitionId}&` : ""
      }`.replace(/&$/, "")
    );

    if (res.ok) {
      return res.json();
    }
    throw new Error(res.statusText);
  }
}
